import React, { useContext, useState } from 'react';
import { UploadContext } from './UploadContext';

const UploadProgressModal = () => {
  const { uploads } = useContext(UploadContext);
  const [showAll, setShowAll] = useState(false); // State to manage visibility of all uploads

  // Toggle function to show/hide all uploads
  const toggleUploads = () => {
    setShowAll((prev) => !prev);
  };

  // Only render the modal if there are uploads
  if (uploads.length === 0) {
    return null; // or you can return a loading message or something else if needed
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 uploadfile_loader_wrapper" style={{}}>
      <div className="">
        {uploads.length > 1 && (
          <div className='multiple_upload_control_row'>
            <h5>
              Uploading {uploads.length} files
            </h5>

            <button
              onClick={toggleUploads}
              className="btn_upload_showall"
            >
              {/* {showAll ? 'Show Less' : 'Show All'} */}
              <i className='mdi mdi-arrow-expand'></i>
            </button>
          </div>
        )}

        {/* Render uploads based on showAll state */}
        {uploads.map((upload, index) => (
          <div key={upload.id} className="mb-4">
            {(showAll || index === 0) && ( // Ensure rendering is based on showAll
              <>
                <div className="flex items-center justify-center mb-2">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mr-2"></div>
                  <div className='uploading_files_with_complete_row'>
                    <h6 className="">{upload.fileName}...</h6>

                    {/* Progress percentage */}
                    <p className="">
                      <span>{Math.round(upload.progress)}</span>% Complete
                    </p>
                  </div>
                </div>

                {/* Progress bar container */}
                <div className="progress-bar-container">
                  <div
                    className="progress-bar"
                    style={{ width: `${upload.progress}%` }}
                  />
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UploadProgressModal;
