import React from 'react';
const Footer = () => {
    return (
        <>
    <footer className="footer" style={{textAlign:'center'}}>
    © 2024 Infomanav . All rights reserved.
    </footer>

    </>
    );
};

export default Footer;