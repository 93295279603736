import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Button, CircularProgress, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

const VideoPlayer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const filename = location.state?.filename;
  const [paused, setPaused] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [videoUri, setVideoUri] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = sessionStorage.getItem("number"); // Replace with your method to get the access token
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
    const loadVideo = async () => {
      setLoading(true);
      try {
        const url = `${apiUrl}/getFile?filePath=${encodeURIComponent(filename)}`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`, // Pass the token in the headers
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const videoBlob = await response.blob();
        setVideoUri(URL.createObjectURL(videoBlob)); // Create a URL for the video blob
      } catch (error) {
        alert('Error loading video stream: ' + error.message);
        navigate("/login"); // Redirect if unauthorized
      } finally {
        setLoading(false);
      }
    };

    if (filename) {
      loadVideo();
    } else {
      alert("Filename not provided.");
      navigate("/"); // Redirect to home or appropriate page if filename is missing
    }
  }, [filename, apiUrl, navigate, token]);

  const togglePlayPause = () => setPaused(!paused);
  const handleProgress = (data) => setCurrentTime(data.playedSeconds);
  const handleDuration = (dur) => setDuration(dur);

  if (loading) {
    return (
      <div className="loading-container">
        <CircularProgress color="secondary" />
        <Typography>Loading...</Typography>
      </div>
    );
  }

  return (
    <div className="video-container">
      <ReactPlayer
        url={videoUri}
        playing={!paused}
        controls={false}
        onProgress={handleProgress}
        onDuration={handleDuration}
        width="100%"
        height="100%"
        progressInterval={1000} // Update every second
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
              crossorigin: 'anonymous',
            },
            tracks: [
              { kind: 'captions', srcLang: 'en', label: 'English' }
            ],
          },
        }}
      />
      {/* Control buttons and sliders can be added here */}
    </div>
  );
};

export default VideoPlayer;



//nav('/VideoViewer/', {state:{ filename:filename, filepath:filepath }});
//const fileName = location.state?.filename;


/*
const openFile = async (file) => {
    const filename = file.fileName;
    try {
      const res = await axios.get(`${apiUrl}getFile`, {
        params: {
          filePath: file.fileName,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'arraybuffer',
      });
      console.log("res", res)
      const exactFile = removeSlash2(file.fileName);
      const fileType = res.headers['content-type'];
      const metadata = res.headers['x-file-metadata']
      console.log("metadata", metadata)
      setCurrentFile(fileTypeExtractor(fileType))


      const blob = new Blob([res.data], { type: fileType });
      const url = window.URL.createObjectURL(blob);
      console.log(fileType)
      if (extractFirstPart(fileType) === 'video') {
        console.log('Handling video...');
        if (fileType === 'video/mp4' || fileType === 'video/webm' || fileType === 'video/ogg' || fileType === 'video/quicktime') {
          nav('/VideoViewer/', {state:{ filename:filename }});

        } else {
          setErrorMessage('This video format is not supported by your browser.');
        }
      } else if (extractFirstPart(fileType) === 'image') {
        setIsOpen(!isOpen);
        console.log('Handling image...');
        const base64Image = arrayBufferToBase64(res.data);

        setImageSrc(`data:${fileType};base64,${base64Image}`)
      }
      else if (fileType === 'application/pdf') {
        console.log('Handling pdf...');

        setOpenPDFModal(true)
        setPdfSrc(url)
        console.log(pdfSrc)
      }
      else if (extractFirstPart(fileType) === 'text') {
        console.log('Handling text file...')
        setOpenPDFModal(true)
        setPdfSrc(url)
        console.log(pdfSrc)
      }

      else if (extractFirstPart(fileType) === 'audio') {

        setIsAudio(true);

        console.log('Handling audio...');
        setAudioSrc(url);

      }
      else {
        console.log('Handling Common file...');
        setFn(removeSlash2(file.fileName))

        setIsOpen(!isOpen)

      }

    } catch (error) {
      showToast('error', 'Error fetching file');
    } finally {

    }
  };
  function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
  const fileTypeExtractor = (file) => {
    const partBeforeSlash = file.split('/')[0];
    return partBeforeSlash
  }
*/