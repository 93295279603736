import React, { useContext, useState,useEffect } from 'react';
import { UploadContext } from './UploadContext';
import { useNavigate } from 'react-router-dom';
import { useSelector ,useDispatch} from 'react-redux';
import {addFolder,incrementCounter,incrementFCounter,removeLastFolder,decrementFCounter} from '../store/fileSlicer'
import axios from "axios"
import { ChakraProvider,  Stack, useToast } from '@chakra-ui/react';

function MoveFolderPopup({moveKey,source,onClose,currentP}) {
  const { addUpload, updateUploadProgress, removeUpload } = useContext(UploadContext);
    const [isRadioChecked, setIsRadioChecked] = useState(false);

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const token = sessionStorage.getItem("number");
    const folderList = useSelector((state)=>state.getdata.folderList)
    const counter = useSelector((state)=>state.getdata.folderCounter)
    const dispatch = useDispatch()
    const sourceFol = source.replace(/\/$/, '')
    console.log("soruce",sourceFol);
    
    
    const [selectedPath, setSelectedPath] = useState(null);
    
    
    

    

    function getTextAfterSlashes(text, counter) {
        if (counter <= 1) {
          return text;
        }
        
      
        const parts = text.split('/');
        
        
        if (parts.length <= counter - 1) {
          return ''; 
        }
        
        return parts.slice(counter - 1).join('/');
      }

      function getTextAfterLastSlash(text) {
   
        const parts = text.split('/');
        
        
        return parts[parts.length - 1];
      }
      const key = moveKey
    const openingActivities = () => {
       
    };
    
    useEffect(() => {
        openingActivities(); 
    }, []); 


    

    const handleItemClick = async(path) => {
        console.log(path) 
        const res = await axios.get(`${apiUrl}getFolder`,{
            params: {
              folderPath: path,
            },
            headers: {
              
              'Authorization': `Bearer ${token}`,
              
            }
          })
          console.log("Response data is",res.data)
          const folders = res.data.filter(item => item?.isFolder === true || item?.isFolder === "true");
          let arr = [folders]

          dispatch(addFolder(arr))
          dispatch(incrementFCounter())
          
    };
    
    const handleMove = async() => {
        
            console.log("Material is",selectedPath,"key",key,"sourceFol",sourceFol)
            try {
              const uploadId = Date.now() + Math.random(); // Ensures a unique uploadId for each file
              addUpload(uploadId, "Moving " + moveKey);
                const res = await axios.post(
                  `${apiUrl}move-folder`,
                  {
                    "sourceFolders": [key],
                    "destinationFolder": selectedPath,
                    
                  },
                  {
                    headers: {
                      'Authorization': `Bearer ${token}`,
                      'Content-Type': 'application/json'
                    },
                    onUploadProgress: (progressEvent) => {
                        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        updateUploadProgress(uploadId, progress);
                    }
                  }
                );
                removeUpload(uploadId)
                console.log("Response is", res.data);
                showToast("success","Folder Moved Successfully!")
                
              } catch (error) {
                showToast("error","Failed to move folder!")
                console.error("Error moving file:", error);
              } finally {
                onClose(); 
              }
        
    };

    const handleBack = () =>{
     if(counter==1){
     
     }else{
        setIsRadioChecked(false);
        dispatch(removeLastFolder())
        dispatch(decrementFCounter())
     }
     
     
    }
    
    const toast = useToast();


  const showToast = (status, message) => {
    toast({
      title: `${status.charAt(0).toUpperCase() + status.slice(1)}`,
      description: message,
      status: status, // Set this to 'error' for a red-colored pop-up
      duration: 3000,
      isClosable: true,
    });
  };
    return (
      <>
    <ChakraProvider>
                
    </ChakraProvider>
        <div className="popup-container">
            <div className="popup">
            {counter==1 ? <div></div>:<button className="close-button" onClick={handleBack}>Back</button>}
                <button className="close-button" onClick={onClose}>×</button>
                <ul>{counter==1?<li>
                <input 
                 type="radio" 
                 name="destination" 
                 onClick={(e) => {
                 e.stopPropagation(); 
                 setIsRadioChecked(true);
                 setSelectedPath("");
                 }}
                 />
                 {"ROOT FOLDER"}
                 </li>:""}
                

                    {folderList[counter-1].map((item, index) => (
                        <li key={index} onClick={()=>handleItemClick(item.fileName)}>
                            <input 
                                type="radio" 
                                name="destination" 
                                value={item.fileName} 
                                onClick={
                                  
                                  (e) => {
                                    e.stopPropagation();
                                    setSelectedPath(item.fileName); 
                                    setIsRadioChecked(true);
                                  }}
                                
                            />
                            {getTextAfterSlashes(item.fileName,counter)}
                        </li>
                    ))}
                </ul>
                <button 
                 className={`btn_gradient btn_width_same btn_full_width btn_red_ripple ripple_effect ${!isRadioChecked ? 'disabled' : ''}`} 
                 onClick={handleMove} 
                 disabled={!isRadioChecked} // Disable the button if no radio button is checked
                >
                    Move
                </button>
            </div>
        </div>
    </>)
}
export default MoveFolderPopup