import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Files from './pages/Files';
import AddFiles from './pages/AddFiles';
import QrCode from './pages/QrCode';
import AddQrCode from './pages/AddQrCode';
import ShortLink from './pages/ShortLink';
import TextDetect from './pages/TextDetect';
import FaceDetect from './pages/FaceDetect';
import ChangePassword from './pages/ChangePassword';
import Table from './pages/Table';
import TestPage from './pages/TestPage';
import TestLightbox from './pages/TestLightbox';
import Signup from './pages/Signup';
import SignupWithGoogle from './pages/SignupWithGoogle';
import NestedPage from './pages/NestedPage';
import Loader from './pages/Loader';
import { UploadProvider } from './pages/UploadContext';
import UploadProgressModal from './pages/UploadProgressModal';
import VideoPlayer from './pages/VideoPlayer';

const App = () => {
  // console.log(window.location.pathname);
  return (
  <UploadProvider>
    <Router basename="/">
    <UploadProgressModal />
      <Routes>
        <Route path="*" element={<Login />} />
        {/* <Route path="/Login" element={<Login />} /> */}
        <Route path="/Home" element={<Home />} />
        <Route path="/Files" element={<Files />} />
        <Route path="/AddFiles" element={<AddFiles />} />
        <Route path="/QrCode" element={<QrCode />} />
        <Route path="/AddQrCode" element={<AddQrCode />} />
        <Route path="/ShortLink" element={<ShortLink />} />
        <Route path="/TextDetect" element={<TextDetect />} />
        <Route path="/FaceDetect" element={<FaceDetect />} />
        <Route path="/ChangePassword" element={<ChangePassword />} />
        <Route path="/Table" element={<Table />} />
        <Route path="/TestPage" element={<TestPage />} />
        <Route path="/TestLightbox" element={<TestLightbox />} />
        <Route path="/Signup" element={<Signup />} />
        <Route path="/SignupWithGoogle" element={<SignupWithGoogle />} />
        <Route path="/VideoPlayer" element={<VideoPlayer/>} />

        <Route path="/nested/:folderId" element={<NestedPage />} />
        <Route path="/Loader" element={<Loader />} />



      </Routes>
    </Router>
    </UploadProvider>
  );
};

export default App;
